/* About menu */
const menu = document.querySelector("#main-nav");
const showHideButton = document.createElement("button");
const icon = document.createElement("img");
const p = document.createElement("p");
icon.setAttribute("src", "./src/images/menu.svg");
icon.setAttribute("alt", "");
icon.classList.add("nav-menu__icon");
menu.classList.add("nav-menu__fixedList");

showHideButton.setAttribute("type", "button");
showHideButton.setAttribute("aria-controls", "main-nav");
showHideButton.classList.add("nav-menu__button");
p.classList.add("nav-menu__buttonText");
showHideButton.appendChild(p);
showHideButton.appendChild(icon);
const buttonContainer = document.createElement("div");
buttonContainer.classList.add("nav-menu__buttonCase");
buttonContainer.appendChild(showHideButton);
menu.parentNode.insertBefore(buttonContainer, menu);

const showMenu = () => {
  p.innerHTML = "Zamknij menu";
  icon.classList.add("nav-menu__icon--hidden");
  p.classList.add("nav-menu__buttonText--changed");
  setTimeout(() => {
    icon.setAttribute("src", "./src/images/closemenu.svg");
    icon.classList.remove("nav-menu__icon--hidden");
  }, 300);
  showHideButton.setAttribute("aria-expanded", "true");
  menu.classList.remove("nav-menu__fixedList--hidden");
};

const hideMenu = () => {
  showHideButton.setAttribute("aria-expanded", "false");
  p.innerHTML = "Otwórz menu";
  icon.classList.add("nav-menu__icon--hidden");
  p.classList.remove("nav-menu__buttonText--changed");
  setTimeout(() => {
    icon.setAttribute("src", "./src/images/menu.svg");
    icon.classList.remove("nav-menu__icon--hidden");
  }, 300);
  menu.classList.add("nav-menu__fixedList--hidden");
};

const menuLinks = document.querySelectorAll(".nav-menu__link");
for (var i = 0; i < menuLinks.length; i++) {
  menuLinks[i].addEventListener("click", () => {
    hideMenu();
  });
}

const buttonCase = document.querySelector(".nav-menu__buttonCase");
document.addEventListener("scroll", () => {
  const pos = window.scrollY;
  if (pos > 200) {
    if (!buttonCase.classList.contains("nav-menu__buttonCase--fixed")) {
      buttonCase.classList.add("nav-menu__buttonCase--fixed");
    }
  } else {
    if (buttonCase.classList.contains("nav-menu__buttonCase--fixed")) {
      buttonCase.classList.remove("nav-menu__buttonCase--fixed");
    }
  }
});

showHideButton.addEventListener(
  "click",
  () => {
    if (menu.classList.contains("nav-menu__fixedList--hidden")) showMenu();
    else hideMenu();
  },
  hideMenu()
);

/* Glider */

window.addEventListener("load", function() {
  new Glider(document.querySelector(".glider"), {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: "#dots",
    draggable: true,
    arrows: {
      prev: ".glider-prev",
      next: ".glider-next"
    },
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: "auto",
          slidesToScroll: "auto",
          duration: 0.25
        }
      }
    ],
    rewind: true
  });
});

/* Video */

function addVideoOnDesktop() {
  if (window.matchMedia("(min-width: 1000px)").matches) {
    if (!document.querySelector(".presentation__source")) {
      const video = document.querySelector(".presentation__video");
      const source = document.createElement("source");
      source.setAttribute("src", video.getAttribute("data-src"));
      source.setAttribute("type", "video/mp4");
      source.classList.add("presentation__source");
      video.appendChild(source);
      window.removeEventListener("resize", addVideoOnDesktop);
    }
  }
}

addVideoOnDesktop();

window.addEventListener("resize", addVideoOnDesktop);
